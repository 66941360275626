<template>
  <span class="k-input-container">
    <span class="k-input">
      <span class="k-input__prefix">
        <i class="k-date-input-masked__icon far fa-calendar"></i>
      </span>
      <span class="k-input__input-field-wrapper">
        <InputMasked
          type="text"
          mask="11-11-1111"
          placeholder="MM-DD-YYYY"
          :placeholder-char="'MM-DD-YYYY'.split('')"
          v-model="date"
          class="k-input__input-field k-date-input-masked__input-field"
        />
      </span>
    </span>
    <div class="k-date-input-masked__error-text" v-if="errorText">{{ errorText }}</div>
  </span>
</template>

<script>
import InputMasked from '@aymkdn/vue-input-masked';

export default {
  name: 'k-date-input-masked',
  components: {
    InputMasked,
  },
  props: {
    value: {
      type: String,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    required: {
      value: Boolean,
    },
    disabled: {
      value: Boolean,
    },
    errorText: {
      type: String,
    },
  },
  watch: {
    date: function value(newValue) {
      this.onChange(newValue);
    },
  },
  data() {
    return {
      date: this.value,
    };
  },
};

</script>

<style lang="scss" scoped>
.k-date-input-masked__input-field {
  margin-left: calc(var(--grid-unit) * 3);
}

.k-date-input-masked__icon {
  color: var(--gray-60);
}

.k-date-input-masked__error-text {
  color: var(--rust-50);
  margin-top: var(--grid-unit);
}
</style>
