<template>
  <div class="manage-dependents">
    <div
      class="dependent-row"
      v-for="(dependent, index) in dependents"
      :key="index + dependent.id"
    >
      <div class="dependent-fields" @focusout="onBlur">
        <k-form-field label="First name">
          <k-input
            type="text"
            v-model="dependent.firstName"
            autocomplete="nope"
            data-cy="first-name-input"
          />
        </k-form-field>
        <k-form-field label="Last name">
          <k-input
              type="text"
              v-model="dependent.lastName"
              autocomplete="nope"
              data-cy="last-initial-input"
          />
        </k-form-field>
        <k-form-field label="Birth / Due date" class="dependent-fields__birthdate">
          <date-input-masked
            :value="dependent.dateOfBirth"
            :onChange="(newValue) => onDateOfBirthChange(dependent.id, newValue)"
            :errorText="showErrors ? birthDateErrorText(dependent.dateOfBirth) : null"
          />
        </k-form-field>
      </div>
      <system-button
        v-if="showRemoveButton"
        class="remove-child-button"
        small
        inline
        style-type="clear"
        fa-icon="trash"
        color="rust"
        @click="removeDependent(dependent)"
      >
        remove
      </system-button>
    </div>
    <system-button
      class="add-child-button"
      style-type="secondary"
      fa-icon="plus"
      color="periwinkle"
      @click="addDependent"
    >
      Add another child
    </system-button>
    <footer
      class="manage-dependents__footer"
    >
      <template v-if="!this.autoSave && !this.bus">
        <system-button style-type="secondary" @click="onCancel">
          Cancel
        </system-button>
        <system-button @click="onSave" :disabled="!isValid" data-cy="save-dependents-button">
          Save
        </system-button>
      </template>
      <div v-else>
        <template v-if="saving"><span class="fas fa-spinner fa-pulse"></span> saving</template>
      </div>
    </footer>
    <loader :loading="saving && !autoSave"/>
  </div>
</template>

<script>
import KFormField from '@components/forms/form_field.vue';
import SystemButton from '@components/buttons/system-button.vue';
import KInput from '@components/inputs/input.vue';
import Loader from '@components/loader/loader.vue';
import DateInputMasked from '@components/inputs/date-input-masked.vue';

export default {
  components: {
    KFormField,
    SystemButton,
    KInput,
    Loader,
    DateInputMasked,
  },

  props: {
    autoSave: {
      type: Boolean,
      default: false,
    },
    showRemove: {
      type: Boolean,
      default: false,
    },
    bus: Object,
    onChange: {
      type: Function,
      default: (isValid) => isValid,
    },
  },

  data() {
    return {
      dependents: [],
      saving: false,
      isChanged: false,
      showErrors: false,
    };
  },

  async mounted() {
    await this.$store.dispatch('dependents/fetchDependents', { parentId: this.$store.state.user.current.id });
    this.dependents = this.$store.getters['dependents/asArray'];
    this.dependents = this.dependents.map((d) => {
      const formattedDateOfBirth = this.$moment(d.dateOfBirth, 'YYYY-MM-DD').format('MM-DD-YYYY');
      return { ...d, dateOfBirth: formattedDateOfBirth };
    });
    if (!this.dependents.length) this.addDependent();

    if (this.bus) {
      this.bus.$on('save-children', this.onSave);
    }
  },

  beforeDestroy() {
    if (this.bus) {
      this.bus.$off('save-children');
    }
  },
  watch: {
    dependents: {
      handler() {
        this.isChanged = true;
        this.onChange(this.isValid);
      },
      deep: true,
    },
  },
  computed: {
    showRemoveButton() {
      return this.showRemove;
    },

    hasValidBirthDates() {
      return this.dependents
        .every((d) => this.isDateValid(d.dateOfBirth));
    },

    hasFirstAndLastNames() {
      return this.dependents.every((d) => !!d.firstName && !!d.lastName);
    },

    isValid() {
      return this.hasValidBirthDates && this.hasFirstAndLastNames;
    },
  },
  methods: {
    isDateValid(dateString) {
      // we need to convert the date string to be in the format of MM/DD/YYYY
      // for cross browser compatibility with the moment isValid function
      let formattedDateString = '';
      if (dateString.indexOf('-') === 4) {
        // convert YYYY-MM-DD to MM/DD/YYYY
        const [YYYY, MM, DD] = dateString.split('-');
        formattedDateString = `${MM}/${DD}/${YYYY}`;
      }
      // otherwise, convert MM-DD-YYYY to MM/DD/YYYY
      const [MM, DD, YYYY] = dateString.split('-');
      formattedDateString = `${MM}/${DD}/${YYYY}`;
      return this.$moment(formattedDateString).isValid();
    },
    onDateOfBirthChange(dependentId, newValue) {
      const dependentIndex = this.dependents.findIndex((d) => d.id === dependentId);
      const newDependents = [...this.dependents];
      if (dependentIndex !== -1) {
        newDependents[dependentIndex].dateOfBirth = newValue;
      }
      this.dependents = newDependents;
    },
    birthDateErrorText(dateOfBirth) {
      if (!this.isDateValid(dateOfBirth)) {
        return 'Please enter a valid date';
      }
      return '';
    },
    removeDependent(dependent) {
      this.dependents = this.dependents.filter(
        (d) => {
          if (dependent.id !== -1) {
            return d.id !== dependent.id;
          }
          return d.firstName !== dependent.firstName || d.dateOfBirth !== dependent.dateOfBirth;
        },
      );

      if (dependent.id !== -1) {
        this.$store.dispatch('dependents/removeDependent', dependent.id);
      }
    },

    addDependent() {
      const today = this.$moment().format('MM-DD-YYYY');
      this.dependents = [...this.dependents, {
        firstName: `Child #${this.dependents.length + 1}`,
        lastName: (this.$store.state.user.current?.lastName || 'k')[0].toUpperCase(),
        dateOfBirth: today,
        id: -1,
      }];
    },

    onCancel() {
      this.$emit('cancel');
    },

    onBlur(event) {
      const leavingParent = !event.currentTarget.contains(event.relatedTarget);
      if (leavingParent && this.isChanged && this.autoSave) {
        this.onSave();
      }
    },

    async onSave() {
      if (!this.isValid) {
        this.showErrors = true;
        return null;
      }

      try {
        this.saving = true;
        // update birth dates to be in correct format to save (YYYY-MM-DD)
        this.dependents = this.dependents
          .map((d) => {
            const formattedDateOfBirth = this.$moment(d.dateOfBirth, 'MM-DD-YYYY').format('YYYY-MM-DD');
            return { ...d, dateOfBirth: formattedDateOfBirth };
          });
        const { addedDependents } = await this.$store.dispatch('dependents/updateDependents', this.dependents);

        addedDependents.forEach((d1) => {
          const depFromStateIndex = this.dependents
            .findIndex((d2) => d1.firstName === d2.firstName && d1.dateOfBirth && d2.dateOfBirth);
          if (depFromStateIndex !== -1) {
            this.dependents[depFromStateIndex] = { ...this.dependents[depFromStateIndex], ...d1 };
          }
        });
        this.isChanged = false;
        this.$emit('saved', addedDependents.map((d) => d.id));
      } catch (error) {
        this.$store.dispatch('notifications/addToastError', { text: 'You cannot update dependents', error }, { root: true });
      }
      this.saving = false;

      return this.dependents;
    },
  },
};
</script>

<style lang="scss" scoped>
  .dependent-fields {
    width: 100%;

    &__name {
      width: 80%;

      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }

    &__birthdate {
      min-width: 160px;
    }

    &::v-deep label {
      white-space: nowrap;
    }

    > * {
      margin-top: 0 !important;
      margin-bottom: 0;
    }

    @media screen and (min-width: 400px) and (max-width: 768px) {
      width: 65%
    }

    @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: row;

      > * {
        margin-top: 0 !important;
        margin-left: var(--grid-unit);

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .manage-dependents {
    display: flex;
    flex-direction: column;

    &__footer {
      display: flex;
      justify-content: flex-end;

      > * {
        margin-left: var(--grid-unit);

        &:only-child {
          height: 24px;
          margin: 0 auto;
        }
      }
    }
  }

  .add-child-button {
    margin-bottom: calc(var(--grid-unit) * 2);
  }

  .name-inputs {
    display: flex;

    > *:first-child {
      margin-right: var(--grid-unit);
      flex: 1;
    }

    > *:last-child {
      width: 75px;
      margin-right: var(--grid-unit);
    }

    @media screen and (max-width: 768px) {
      margin-bottom: var(--grid-unit);
    }
  }

  .dependent-row {
    width: 100%;
    margin-bottom: var(--grid-unit);
  }

  .dependent-row + .dependent-row {
    padding-top: var(--grid-unit);
    border-top: 1px solid var(--gray-10);
  }
</style>
