<template>
  <modal
    :is-open="isOpen"
    @close="onHide"
  >
    <template #header>
      <h6>Manage your children</h6>
    </template>
    <manage-dependents
      is-open="isOpen"
      @saved="onSaved"
      @cancel="onHide"
      :bus="bus"
      :onChange="onDependentsChange"
    />
    <template #footer>
      <system-button
        style-type="secondary"
        @click="onHide">
        Cancel
      </system-button>
      <system-button
        @click="onSave"
        data-cy="manage-children-save"
        :disabled="!isDependentsDataValid"
      >
        Save
      </system-button>
    </template>
  </modal>
</template>

<script>
import Vue from 'vue';
import Modal from '@components/modal/modal.vue';
import SystemButton from '@components/buttons/system-button.vue';
import ManageDependents from './manage-dependents.vue';

export default {
  components: {
    Modal,
    ManageDependents,
    SystemButton,
  },
  props: {
    isOpen: Boolean,
  },
  data() {
    return {
      bus: new Vue(),
      isDependentsDataValid: false,
    };
  },
  methods: {
    onHide() {
      this.$emit('hide');
    },
    onSave() {
      this.bus.$emit('save-children');
    },
    onSaved(ids) {
      this.$emit('saved', ids);
      this.$emit('hide');
    },
    onDependentsChange(isValid) {
      this.isDependentsDataValid = isValid;
    },
  },
};
</script>
